<template>
<div id="home">
  <!-- cards START -->
  <!-- slide 여러개일 때 -->
  <swiper class="homeSwiper"
    v-if="this.selectContents().length > 1"
    v-bind="swiperOption"
  >
    <swiper-slide class="homeSwiperSlide"
      v-for="(content,idx) in this.selectContents()"
      :key="content.contents_pk"
    >
      <img
        :data-src="content.path+content.save_name"
        class="swiper-lazy"
      />
      <div class="card">
        <div class="text_box">
          <div class="card--category">{{content.division}}</div>
          <div :class="`card--save ${content.save}`" :id="`saveCheck${content.contents_pk}`" @click="setSave(content.contents_pk, $event, idx)" v-if="this.user.token"></div>
          <div class="bottom_box">
            <h2 class="card--title">{{content.title}}</h2>
            <p class="card--text">{{content.sub_title}}</p>
            <button class="btn_view" @click="openHomePop(content)">{{content.button}}</button>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
  <!-- slide 하나일 때 -->
  <swiper class="homeSwiper"
    v-else
    :direction = "'vertical'"
  >
    <swiper-slide class="homeSwiperSlide"
      v-for="content in this.selectContents()"
      :key="content.contents_pk"
    >
      <img :src="content.path+content.save_name" alt="home slide img"/>
      <div class="card">
        <div class="text_box">
          <div class="card--category">{{content.division}}</div>
          <div :class="`card--save ${content.save}`" :id="`saveCheck${content.contents_pk}`" @click="setSave(content.contents_pk, $event)" v-if="this.user.token"></div>
          <div class="bottom_box">
            <h2 class="card--title">{{content.title}}</h2>
            <p class="card--text">{{content.sub_title}}</p>
            <button class="btn_view" @click="openHomePop(content)">{{content.button}}</button>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
  <!-- cards END -->

  <!-- category START -->
  <div class="filter" v-if="showCategory"></div>
  <transition name="slide-up">
    <div class="category"
      v-if="showCategory"
    >
      <button class="btn_categoryClose"
        @click="()=>this.showCategory=false"
      ></button>
      <div class="list_container">
        <label class="list">
          <input type="checkbox" name="category" value="all" class="btn_check" id="check_all"
            :checked="this.categoryFilter.includes('all')" 
            @change="checkAll($event)"
          />
          <span class="checkmark"></span>
          카테고리 전체
        </label>
        <label class="list" v-for="(item, index) in this.category" :key="index">
          <input type="checkbox" name="category" :value="item.division" class="btn_check"
            :checked="this.categoryFilter.includes('all') || this.categoryFilter.includes(item.division)"
            @change="checkNotAll($event)"
          />
          <span class="checkmark"></span>
          {{item.division}}
        </label>
      </div>
      <div class="btn_wrapper">
        <button class="btn_apply"
          @click="categoryApply"
        >
          적용
        </button>
      </div>
    </div>
  </transition>
  
  <button class="btn_categoryOpen"
    v-if="!this.showCategory"
    @click="()=>this.showCategory=true"
  ></button>
  <!-- category END -->
  
</div>
</template>

<script>
// ----------------Swiper----------------
import SwiperCore, { Mousewheel, EffectCreative, Lazy } from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import "swiper/css/effect-creative"
SwiperCore.use([Mousewheel, EffectCreative, Lazy]);
// ----------------Swiper----------------

import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
  },
  computed:{
    ...mapState('user', [ 'user' ]),
  },
  mounted(){
    this.fetchHomeData();
    this.apiLogin();
  },
  data(){
    return {
      showCategory : false,
      showSave: false,
      
      contents : [],
      category : [],
      categoryFilter : ['all'],

      swiperOption:{
        direction : "vertical",
        effect :'creative',
        lazy: {
          loadOnTransitionStart: true,
          loadPrevNext: true, // pre-loads the next image to avoid showing a loading placeholder if possible
          loadPrevNextAmount: 3, //or, if you wish, preload the next 2 images
        },
        slidesPerView : 1.13,
        mousewheel : true,
        spaceBetween : 10,
        loop : true,
        initialSlide : 2,
        creativeEffect : {
          prev: {
            translate: [0, "-5%", -480],
          },
          next: {
            translate: [0, "105%", 0],
          }
        }
      },
    }
  },
  // 카테고리 창 열려있을때 뒤로가기 막고 창닫기
  beforeRouteLeave (to, from, next) {
    if(this.showCategory){
      this.showCategory = false;
      next(false);
    } else{
      next();
    }
  },
  methods:{
    apiLogin(){
      if (this.$route.query.t) {
        const loadingTimeSet = setTimeout(() => {
          this.$store.commit('startLoading');
        }, 500);

        let os = "";
        var varUA = navigator.userAgent.toLowerCase();

        if (varUA.indexOf('android') > -1) {
          os = "android";
        } else if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -
            1) {
          os = "ios";
        }

        const url = '/login/apiLogin';
        this.axios.get(url, {
          params : {
            access_token: this.$route.query.t,
            os: os
          }
        })
        .then(res=>{
          clearTimeout(loadingTimeSet);
          this.$store.commit('endLoading');

          if(res.data.success){
            this.$store.commit('user/login', res.data.user);
            this.loginFail = false;
            if(res.data.self_certification_yn == 'false'){
              this.$router.push('/certificate');
            }

            if (window.wao) {
              window.wao.autoLogin(String(this.user.id), String(this.user.token))
            }
            if (window.webkit) {
              window.webkit.messageHandlers.wao.postMessage({
                type: 'autoLogin',
                value: {
                  pk: String(this.user.id),
                  token: this.user.token
                }
              });
            }

          } else {
            this.loginFail = true;
            this.$store.commit('user/logout');
          }
        });
      } else {
        return false;
      }
    },
    fetchHomeData(){
      // 본인인증 없을 시
      if(this.user.token && this.user.self_certification_yn == 'false'){
        this.$router.push('/certificate');
      }

      // 필요한 데이터 가져오기
      const url = '/weareowner/home/main'
      const loadingTimeSet = setTimeout(() => {
        this.$store.commit('startLoading');
      }, 500);
      this.axios.get(url, {
        params : {
          access_token : this.user.token,
        }
      })
      .then(res=>{
        this.contents = res.data.contents;
        this.category = res.data.category;

        clearTimeout(loadingTimeSet);
        this.$store.commit('endLoading');
      });
    },
    // 적용된 카테고리의 content array 반환
    selectContents(){
      const result =  this.contents.filter(el=> {
        return this.categoryFilter.includes('all') || this.categoryFilter.includes(el.division)
      })
      return result;
    },

    // 카테고리에서 '카테고리전체' 선택시
    checkAll(event){
      const checkboxes = document.querySelectorAll('.btn_check:not(#check_all)');
      if(event.currentTarget.checked){
        checkboxes.forEach(el=> el.checked = true);
      } else{
        checkboxes.forEach(el=> el.checked = false);
      }
    },

    // 카테고리에서 부분 선택 해제시
    checkNotAll(event){
      const checkAll = document.querySelector('#check_all');
      if(!event.currentTarget.checked){
        checkAll.checked = false;
      }
    },

    // 카테고리 적용 버튼
    categoryApply(){
      const checkAll = document.querySelector('#check_all');
      const checkedCategories = document.querySelectorAll('.btn_check:not(#check_all):checked');
      if(checkAll.checked){     // 전체 선택시
        this.categoryFilter = ['all'];
        this.showCategory=false;
      } else if(checkedCategories.length != 0){   // 부분 선택시
        this.categoryFilter = Array.from(checkedCategories).map(el => el.value);
        this.showCategory=false;
      } else{   //아무것도 선택 안할 시
        alert('하나 이상 선택해 주세요')
      }
    },

    // 저장 버튼
    setSave(contents_pk, event, idx) {
      const loadingTimeSet = setTimeout(() => {
        this.$store.commit('startLoading');
      }, 500);
      const url = '/weareowner/home/save'
      
      this.contents[idx].save = "on"; // 로컬에다 받아왔던 contents에도 반영
      this.axios.get(url, {
        params : {
          access_token : this.user.token,
          contents_pk : contents_pk
        }
      })
      .then(res=>{
        clearTimeout(loadingTimeSet);
        this.$store.commit('endLoading');

        if(!res.data.success){
          this.$store.commit('user/logout');
          this.$router.push('/login');
        } else {
          if(res.data.save) {
            event.target.classList.add(res.data.save);
          } else {
            event.target.classList.remove("on");
          }
        }
      });
    },

    openHomePop(content) {
      this.$store.commit('home/openHomePop', content);
      this.$router.push('/home/homepop');
    },
  }
}
</script>

<style scoped src='@/assets/css/css-custom/views/home.css'>
</style>