import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home/Home'
import Owner from '../views/Owner/Owner'
import Weare from '../views/Weare/Weare'
import Support from '../views/Support/Support'
import Mypage from '../views/Mypage/Mypage'

import store from '@/store';
import axios from 'axios';

const routes = [
  { // Home
    path: '/',
    name: 'Home',
    component: Home
  },
  { // Home - HomePop
    path: '/home/homepop',
    name: 'HomePop',
    component: () => import(/* webpackChunkName: "HomePop" */ '../views/Home/HomePop')
  },

  { // Weare
    path: '/weare',
    name: 'Weare',
    component: Weare
  },
  { // Weare - WeareDetailPop
    path: '/weare/wearedetailpop',
    name: 'WeareDetailPop',
    component: () => import(/* webpackChunkName: "WeareDetailPop" */ '../views/Weare/WeareDetailPop')
  },
  { // Weare - WeareDetailPop
    path: '/weare/wearelistpop',
    name: 'WeareListPop',
    component: () => import(/* webpackChunkName: "WeareListPop" */ '../views/Weare/WeareListPop')
  },

  { // Owner
    path: '/owner',
    name: 'Owner',
    component: Owner
  },
  { // Owner - OwnerPop
    path: '/owner/ownerpop',
    name: 'OwnerPop',
    component: () => import(/* webpackChunkName: "OwnerPop" */ '../views/Owner/OwnerPop')
  },
  { // Owner - OwnerSubPop
    path: '/owner/ownersubpop',
    name: 'OwnerSubPop',
    component: () => import(/* webpackChunkName: "OwnerSubPop" */ '../views/Owner/OwnerSubPop')
  },

  { // Support
    path: '/support',
    name: 'Support',
    component: Support
  },
  { // Support - SupportPop
    path: '/support/supportpop',
    name: 'SupportPop',
    component: () => import(/* webpackChunkName: "SupportPop" */ '../views/Support/SupportPop')
  },
  { // Support - SupportSubPop
    path: '/support/supportsubpop',
    name: 'SupportSubPop',
    component: () => import(/* webpackChunkName: "SupportSubPop" */ '../views/Support/SupportSubPop')
  },

  { // Mypage
    path: '/mypage',
    name: 'Mypage',
    component: Mypage,
    beforeEnter: (to, from, next) => {
      if(store.state.user.user.token){
        axios.get('/weareowner/common/tokenCheck', {
          params:{
            access_token : store.state.user.user.token
          }
        }).then(res=>{
          if(res.data.success){
            next();
          }
          else{
            store.commit('user/logout');
            next('/login');
          }
        })
        
      } else{
        next('/login');
      }
    }
  },
  { // Mypage - MypagePop
    path: '/mypage/mypagepop',
    name: 'MypagePop',
    component: () => import(/* webpackChunkName: "MypagePop" */ '../views/Mypage/MypagePop')
  },
  { // Mypage - MypageSubPop
    path: '/mypage/mypagesubpop',
    name: 'MypageSubPop',
    component: () => import(/* webpackChunkName: "MypageSubPop" */ '../views/Mypage/MypageSubPop')
  },

  { // Login
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login/Login'),
    beforeEnter: (to, from, next) => {
      if(!store.state.user.user.token){
        next();
      } else{
        next('/')
      }
    }
  },
  { // Login - loginform
    path: '/login/loginform',
    name: 'LoginFrom',
    component: () => import(/* webpackChunkName: "LoginForm" */ '../views/Login/LoginForm'),
    beforeEnter: (to, from, next) => {
      if(!store.state.user.user.token){
        next();
      } else{
        next('/')
      }
    }
  },

  { // Estimate
    path: '/estimate',
    name: 'Estimate',
    component: () => import(/* webpackChunkName: "Estimate" */ '../views/Estimate'),
  },
  
  { // Certificate
    path: '/certificate',
    name: 'Certificate',
    component: () => import(/* webpackChunkName: "Certificate" */ '../views/Certificate')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
