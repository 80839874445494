export default {
    namespaced : true,
    state : {
        mypage: {
            whichPop : "",
            consulting : {},
            startup : {},
            inquire : {},
            bookmark : {},
        },
        mypagePop : {
            whichSubPop : '',
        },
    },
    mutations : {
        // mypagePop
        openMypagePop(state, payload){
            state.mypage.whichPop = payload;
        },
        closeMypagePop(state){
            state.mypage.whichPop = '';
            state.mypage.consulting = {};
            state.mypage.startup = {};
            state.mypage.inquire = {};
            state.mypage.bookmark = {};
        },
        // mypageSubPop
        openMypageSubPop(state, payload){
            state.mypagePop.whichSubPop = payload;
        },
        closeMypageSubPop(state){
            state.mypagePop.whichSubPop = '';
        },
    }
}