<template>
  <div id="weare">
    <!-- main_visual START -->
    <section class="main_visual">
      <div class="imgWrapper">
        <img src="@/assets/images/main/main_visual.png" alt="visual" />
      </div>
      <div class="bottom_box">
        <h3>AI 창업견적서비스</h3>
        <p>
          안녕하세요. 고객님!<br />
          어떤 견적을 받아보실껀가요?
        </p>
        <button class="btn_goto" @click="openEstimate">
          <img src="@/assets/images/sub/icon/icon_visual_main.svg" alt="" />
          <span>AI 내 예산 맞춤 견적</span>
        </button>
        <!-- <button class="btn_goto" @click="openUrl('https://ownersite.co.kr/estimate/select')">
          <img src="@/assets/images/sub/icon/icon_visual_main2.svg" alt="" />
          <span>내가 선택하는 견적</span>
        </button> -->
      </div>
    </section>
    <!-- main_visual END -->

    <template
      v-for="ui_category in this.ui_categories"
      :key="ui_category.ui_category_pk"
    >
      <!-- 창업 슬라이드 START -->
      <section
        class="founded ui_category"
        v-if="ui_category.link == 'founded' && ui_category.use_yn"
      >
        <h2 class="sectionTitle">{{ui_category.name}}</h2>
        <p class="sectionText">{{ui_category.content}}</p>
        <button
          class="btn_more"
          @click="openListPop(ui_category, this.ui.founded)"
        >
          더보기
        </button>
        <swiper v-bind="swiperOption1">
          <swiper-slide
            v-for="item in this.ui.founded"
            :key="item.founded_pk"
            @click="openDetailPop(ui_category, item)"
          >
            <div class="imgWrapper">
              <img
                :src="item.path + item.save_name"
                :alt="item.original_name"
              />
            </div>
            <div class="tagBox">
              <h2 class="tagTitle">{{item.name}}</h2>
              <p class="tagText">{{item.content}}</p>
              <ul class="tagContents">
                <li>
                  <span>인테리어 비용</span>
                  <span>
                    약<strong>{{numberFormat(item.interior_price)}}</strong>원
                  </span>
                </li>
                <li>
                  <span>물품 비용</span>
                  <span>
                    약<strong>{{numberFormat(item.object_price)}}</strong>원
                  </span>
                </li>
                <li>
                  <span>월 운영 비용</span>
                  <span>
                    약<strong>{{numberFormat(item.operation_price)}}</strong>원
                  </span>
                </li>
              </ul>
            </div>
          </swiper-slide>
        </swiper>
      </section>
      <!-- 창업 슬라이드 START -->

      <!-- 추천창업 START -->
      <section
        class="recommend ui_category"
        v-if="ui_category.link == 'recommend' && ui_category.use_yn"
      >
        <h2 class="sectionTitle">{{ui_category.name}}</h2>
        <p class="sectionText">{{ui_category.content}}</p>
        <ul class="item_container">
          <li
            class="item"
            v-for="recommend in this.ui.recommend"
            :key="recommend.service.service_pk"
            @click="openOwnerService(recommend.category, recommend.service)"
          >
            <div class="circle">
              <img
                v-if="recommend.service.category_pk == 13"
                src="@/assets/images/main/interior.svg"
                alt="interior"
              />
              <img
                v-else-if="recommend.service.category_pk == 25"
                src="@/assets/images/main/rental.svg"
                alt="rental"
              />
              <img
                v-else
                :src="`${recommend.service.path}${recommend.service.save_name}`"
                :alt="recommend.service.original_name"
              />
              <div class="low-title-icon"></div>
            </div>
            <div class="item_name" v-if="recommend.service.category_pk == 13">
              <p>인테리어</p>
              <p>20평대</p>
            </div>
            <div class="item_name"
              v-else-if="recommend.service.category_pk == 25"
            >
              <p>렌탈</p>
              <p>보안, 방역</p>
            </div>
            <div class="item_name" v-else>
              <p>{{recommend.service.name}}</p>
              <p>{{recommend.service.link}}</p>
            </div>
          </li>
        </ul>
      </section>
      <!-- 추천창업 END -->

      <!-- 광고배너 START -->
      <section class="ad_banner ui_category"
        v-if="ui_category.link == 'banner' && ui_category.use_yn=='false'"
      >
        <img src="@/assets//images/sub/banner/ad_banner.gif" />
      </section>
      <section
        class="ad_banner ui_category"
        v-if="ui_category.link == 'banner' && ui_category.use_yn=='true'"
      >
        <swiper v-bind="swiperOption2">
          <swiper-slide v-for="item in ui.banner" :key="item.banner_pk">
            <div class="imgWrapper">
              <img
                :src="`${item.path}${item.save_name}`"
                :alt="item.original_name"
              />
            </div>
          </swiper-slide>
        </swiper>
      </section>
      <!-- 광고배너 END -->

      <!-- 매거진 START -->
      <section
        class="magazine ui_category"
        v-if="ui_category.link == 'magazine' && ui_category.use_yn"
      >
        <h2 class="sectionTitle">{{ui_category.name}}</h2>
        <p class="sectionText">{{ui_category.content}}</p>
        <button
          class="btn_more"
          @click="openListPop(ui_category, this.ui.magazine)"
        >
          더보기
        </button>
        <ul>
          <li
            v-for="item in this.ui.magazine.slice(0, 4)"
            :key="item.magazine_pk"
            @click="openDetailPop(ui_category, item)"
          >
            <p class="magazineTitle">
              {{item.title}} <span>{{item.enrollment_date}}</span>
            </p>
            <img
              :src="`${item.path}${item.save_name}`"
              :alt="item.original_name"
            />
          </li>
        </ul>
      </section>
      <!-- 매거진 END -->

      <!-- 위아오너 제휴사 프로모션(광고) START -->
      <section
        class="promotion ui_category"
        v-if="ui_category.link == 'promotion' && ui_category.use_yn"
      >
        <h2 class="sectionTitle">{{ui_category.name}}</h2>
        <p class="sectionText">{{ui_category.content}}</p>
        <button
          class="btn_more"
          @click="openListPop(ui_category, this.ui.promotion)"
        >
          더보기
        </button>
        <swiper v-bind="swiperOption2">
          <swiper-slide
            v-for="item in this.ui.promotion"
            :key="item.promotion_pk"
            @click="openDetailPop(ui_category, item)"
          >
            <img
              :src="`${item.path}${item.save_name}`"
              :alt="item.original_name"
            />
          </swiper-slide>
        </swiper>
      </section>
      <!-- 위아오너 제휴사 프로모션(광고) END -->

      <!-- 입점사 슬라이드 START -->
      <section
        class="coalition ui_category"
        v-if="ui_category.link == 'coalition' && ui_category.use_yn"
      >
        <swiper v-bind="swiperOption3">
          <swiper-slide
            v-for="item in this.ui.coalition"
            :key="item.coalition_pk"
          >
            <img
              :src="`${item.path}${item.save_name}`"
              :alt="item.original_name"
            />
          </swiper-slide>
        </swiper>
      </section>

      <!-- 입점사 슬라이드 END -->
    </template>

  </div>
</template>

<script>
// ----------------Swiper----------------
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
SwiperCore.use([Autoplay, Pagination]);
// ----------------Swiper----------------

import { numberFormat } from "@/utils/numberFormat";
// import { openUrl } from "@/utils/openUrl";

export default {
  name: "Weare",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      ui_categories: [],
      ui: {},

      swiperOption1: {
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        slidesPerView: 1.13,
        spaceBetween: 20,
      },
      swiperOption2: {
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        pagination: {
          clickable: true,
        },
      },
      swiperOption3: {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
    };
  },
  mounted() {
    this.fetchWeareData();
  },
  watch: {},
  // mixins:[ numberFormat, openUrl ],
  mixins:[ numberFormat ],
  methods: {
    fetchWeareData() {
      const loadingTimeSet = setTimeout(() => {
        this.$store.commit('startLoading');
      }, 500);

      const url = '/weareowner/weare/main';
      this.axios.get(url)
        .then((res) => {
          this.ui_categories = res.data.category;
          this.ui = res.data.ui;
          
          clearTimeout(loadingTimeSet);
          this.$store.commit('endLoading');
        });
    },
    // weare pop들
    openDetailPop(ui_category, popContent) {
      this.$store.commit('weare/openDetailPop', {
        ui_category : ui_category,
        popContent : popContent,
      })
      this.$router.push('/weare/wearedetailpop');
    },
    openListPop(ui_category, popList) {
      this.$store.commit('weare/openListPop', {
        ui_category : ui_category,
        popList : popList,
      })
      this.$router.push('/weare/wearelistpop');
    },
    // 창업 견적 서비스
    openEstimate() {
      if(this.$store.state.user.user.token){
        this.$router.push('/estimate');
      } else {
        this.$router.push('/login');
      }
    },
    // 추천 오너 서비스
    openOwnerService(category, service) {
      let detail = {};

      if (service.division_code == "002") {
        const loadingTimeSet = setTimeout(() => {
          this.$store.commit('startLoading');
        }, 500);
      
        const url = '/weareowner/owner/detail';
        this.axios.get(url, {
          params:{
            code : category.code,
            category_pk : service.category_pk
          }
        })
        .then((res) => {
          clearTimeout(loadingTimeSet);
          this.$store.commit('endLoading');
          
          res.data.service.forEach((el) => {
            if (el.service_pk == service.service_pk) {
              detail = el;
            }
          });

          this.$store.commit('owner/openOwnerSubPop', {
            category : category,
            detail : detail
          });
          this.$router.push('/owner/ownersubpop');
        });
      } else {
        this.$store.commit('owner/openOwnerPop', {
          category : category,
          sub_category : service
        })
        this.$router.push('/owner/ownerpop');
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/css-custom/views/weare.css"></style>
