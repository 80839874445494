import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import user from './modules/user'

import home from './modules/home'
import weare from './modules/weare'
import owner from './modules/owner'
import support from './modules/support'
import mypage from './modules/mypage'

export default createStore({
  state: {
    consulting : {
      type : "",
      pk : "",
      category_pk : "",
      option : "",
      content_html : "",

      es_division : "",
    },
    loadingStatus : false,
  },
  plugins:[ createPersistedState({
    paths : ['user', 'home', 'weare', 'owner', 'support', 'mypage']
  }) ],
  mutations: {
    // loading시 gif 보임 상태
    startLoading(state){
      state.loadingStatus = true;
    },
    endLoading(state){
      state.loadingStatus = false;
    },
    selectType(state, payload){
      state.consulting = payload;
    },
  },
  modules: {
    user,
    home, weare, owner, support, mypage,
  },
})
