import axios from 'axios';

export default {
  namespaced: true,
  state: {
    user: {
      id: "",
      token: "",
      name: "",
      email: "",
      contact: "",
      recommend_enrollment_yn: "",
    },
  },
  mutations: {
    login(state, payload) {
      state.user = payload;
    },
    logout(state) {
      axios.get('/logout', {
        params: {
          access_token: state.user.token
        }
      }).then(res => {
        if (res.data.success) {
          state.user = {
            id: "",
            token: "",
            name: "",
            email: "",
            contact: "",
          };
          if (window.wao) {
            window.wao.logout()
          }
          if (window.webkit) {
            window.webkit.messageHandlers.wao.postMessage({
              type: 'logout',
              value: {}
            });
          }
        } else {
          alert(res.data.message);
        }
      })
    }
  }
}