<template>
<div id="support">
  <!-- main_visual START -->
  <section class="main_visual">
    <div class="imgWrapper">
      <img src="@/assets/images/main/support_visual.png" alt="visual" />
    </div>
    <div class="bottom_box">
      <h3>지원사업조회</h3>
      <p>지원사업 정보 중에서 <br />나의 조건에 맞는 사업정보를 검색 하세요.</p>
      <button class="btn_goto"
        @click="openPop"
      >
        <img src="@/assets/images/sub/icon/icon_visual_support.svg" alt="" />
        <span>조회 하러 가기</span>
      </button>
    </div>
  </section>
  <!-- main_visual END -->

  <!-- support_ad START -->
  <section class="support_ad">
    <!-- <adfit-banner
      :ad-test="N"
      :ad-unit="DAN-m84Ix2nZjfoEcIbx"
      @:ad-loaded="adLoaded"
      @:ad-failed="adFailed"
    /> -->
  </section>
  <!-- support_ad START -->
</div>
</template>


<script>
export default {
  name: 'Support',
  methods:{
    openPop(){
      this.$router.push('/support/supportpop');
    },
    // // eslint-disable-next-line no-unused-vars
    // adLoaded (el) {
    //   console.info('Ad Loaded')
    // },
    // // eslint-disable-next-line no-unused-vars
    // adFailed (el) {
    //   console.error('Ad Failed')
    // }
  }
}
</script>
<style scoped src="@/assets/css/css-custom/views/support.css">
</style>
