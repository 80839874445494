<template>
  <div class="filter"
    v-if="this.user.recommend_enrollment_yn == 'false'"
  ></div>
  <transition name="slide-up">
    <div class="recommend"
      v-if="this.user.recommend_enrollment_yn == 'false'"
    >
      <label>
        <p>추천인 등록</p>
        <input type="text" v-model="contact" class="input_basic" placeholder="전화번호를 입력해주세요" maxlength="13">
        <div class="recommend_text">
          <p>추천인이 없으시거나 나중에 등록 하실려면 <strong>[나중에]</strong> 버튼을 눌러주세요.</p>
          <p>추천인 등록은 <strong>마이페이지 > 설정 > 추천인등록</strong>을 통해서도 등록하실 수 있습니다.</p>
        </div>
      </label>

      <div class="btn_wrapper">
        <button class="btn_apply" disabled
          @click="clickApply"
        >저장</button>
        <button class="btn_categoryClose"
          @click="clickLater"
        >나중에</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { phoneFormat } from '@/utils/phoneFormat';
import { mapState } from 'vuex'

export default {
  computed:{
    ...mapState('user', [ 'user' ]),
  },
  data(){
    return {
      contact : "",
    }
  },
  mixins : [ phoneFormat ],
  watch:{
    contact(newPhone){
      this.contact = this.phoneFormat(newPhone);
      if(this.contact.length){
        document.querySelector('.btn_apply').disabled = false;
      } else{
        document.querySelector('.btn_apply').disabled = true;
      }
    }
  },
  methods:{
    clickApply(){
      const url = "/weareowner/common/saveRecommed"
      this.axios.get(url, {
        params : {
          access_token : this.user.token,
          contact : this.contact,
        }
      }).then(res=>{
        this.user.recommend_enrollment_yn = "true";
        if(!res.data.success){
          alert(res.data.message);
        }
      });

    },
    clickLater(){
      const url = "/weareowner/common/saveRecommed"

      alert('나중에 마이페이지에서 추가하실 수 있습니다.');
      this.axios.get(url, {
        params : {
          access_token : this.user.token,
        }
      }).then(res=>{
        this.user.recommend_enrollment_yn = "true";
        if(!res.data.success){
          alert(res.data.message);
        }
      });
    }
  }
}
</script>

<style scoped>
/* recommend */
.filter{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    z-index: 1000;
}
.recommend{
    overflow: hidden;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60%;
    z-index: 1000;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 2rem 1rem 1rem;
}
.recommend label p{
    font-size: 2rem;
    font-weight: 500;
    text-indent: .5rem;
    padding-bottom: 1rem;
}
.recommend label .recommend_text {
    margin-top: 2rem;
    padding-left: 0.5rem;
}
.recommend label .recommend_text p {
    font-size: 1.6rem;
    font-weight: 400;
    color: #555;
    line-height: 1.7;
    text-indent: 0;
}
.recommend label .recommend_text p + p {
    margin-top: 0.6rem;
}
.recommend label .recommend_text p strong {
    color: var(--active);
    font-weight: 500;
}
.recommend label input{
  margin-top: 1rem;
  font-size: 1.8rem;
}


.recommend .btn_wrapper{
    position: absolute;
    bottom: 0;
    padding: 1rem;
    margin-left: -1rem;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #e7e7e7;
    text-align: center;
    display: grid;
    grid-template-columns : repeat(2, 1fr);
    grid-gap: 1rem;
}
.recommend .btn_wrapper button{
    font-size: 1.6rem;
    padding: 1rem 0;
    border-radius: 1rem;
    border: 1px solid #0088CC;
}
.recommend .btn_wrapper button:disabled{
  background-color: #ddd;
  border-color: #ddd;
}
</style>