<template>
  <Loading />
  
  <Recommend />

  <router-view v-slot="{ Component }">
    <transition :name="transitionName">
      <component :is="Component" />
    </transition>
  </router-view>

  <button v-show="showChannelTalk" class="channelTalk_button"></button>

  <div class="bottomMenu">
    <router-link to="/">홈</router-link>
    <router-link to="/weare">위아서비스</router-link>
    <router-link to="/owner">오너서비스</router-link>
    <router-link to="/support">지원사업</router-link>
    <router-link to="/mypage">마이페이지</router-link>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import Recommend from '@/components/Recommend'
import ChannelService from '@/utils/channelTack'

export default {
  components:{
    Loading,
    Recommend
  },
  computed: {
    showChannelTalk(){
      return this.$store.state.user.user.email == 'test@test.com' 
        && ( this.$route.path == '/'
        || this.$route.path == '/weare' 
        || this.$route.path == '/owner'
        || this.$route.path == '/support')
    }
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      if(toDepth == fromDepth){
        this.transitionName = 'fade';
      }
      if(toDepth > fromDepth ){
        this.transitionName = 'slide-right';
      }
      if(toDepth < fromDepth ){
        this.transitionName = 'slide-left';
      }
    }
  },
  data(){
    return {
      transitionName : '',
    }
  },
  mounted(){
    // Boot Channel as an anonymous user
    ChannelService.boot({
      "pluginKey": "4f197be0-0403-4446-a34c-f456cd9fa669", //please fill with your plugin key
      "customLauncherSelector": ".channelTalk_button",
      "hideChannelButtonOnBoot": true,
    });

    // Boot Channel as a registered user
    // ChannelService.boot({
    //   "pluginKey": "YOUR_PLUGIN_KEY", //please fill with your plugin key
    //   "profile": {
    //     "name": "YOUR_USER_NAME", //fill with user name
    //     "mobileNumber": "YOUR_USER_MOBILE_NUMBER", //fill with user phone number
    //     "CUSTOM_VALUE_1": "VALUE_1", //any other custom meta data
    //     "CUSTOM_VALUE_2": "VALUE_2"
    //   }
    // });

    // Shutdown Channel
    // ChannelService.shutdown();
  }
}
</script>


<style>
@import url('@/assets/css/css-lib/reset.css');
@import url('@/assets/css/css-custom/common.css');

.channelTalk_button{
  position: fixed;
  bottom: 8rem;
  right: 2.5rem;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, .4);
  z-index: 100;
  background: url("~@/assets/images/common/channelTalk.svg") no-repeat center center/ 60% #fff;
}

/* bottomMenu */
.bottomMenu {
  width: 100%;
  height: 6rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #eee;
  z-index: 10;
}
.bottomMenu a {
  padding: 3.5rem 0 1.1rem 0;
  box-sizing: border-box;
  width: 20%;
  color: var(--greyText);
  background-size: 2rem;
  background-color: #fff;
  background-position: 50% 30%;
  background-repeat: no-repeat;
}
.bottomMenu a:nth-child(1) {
    background-image: url("@/assets/images/common/bottomMenu_home.svg");
}
.bottomMenu a:nth-child(2) {
    background-image: url("@/assets/images/common/bottomMenu_weare.svg");
}
.bottomMenu a:nth-child(3) {
    background-image: url("@/assets/images/common/bottomMenu_owner.svg");
}
.bottomMenu a:nth-child(4) {
	background-image: url("@/assets/images/common/bottomMenu_support.svg");
}
.bottomMenu a:nth-child(5) {
    background-image: url("@/assets/images/common/bottomMenu_mypage.svg");
}
.bottomMenu a.router-link-exact-active {
  color: var(--active);
}
.bottomMenu a.router-link-exact-active:nth-child(1) {
    background-image: url("@/assets/images/common/bottomMenu_home_on.svg");
}
.bottomMenu a.router-link-exact-active:nth-child(2) {
    background-image: url("@/assets/images/common/bottomMenu_weare_on.svg");
}
.bottomMenu a.router-link-exact-active:nth-child(3) {
    background-image: url("@/assets/images/common/bottomMenu_owner_on.svg");
}
.bottomMenu a.router-link-exact-active:nth-child(4) {
	background-image: url("@/assets/images/common/bottomMenu_support_on.svg");
}
.bottomMenu a.router-link-exact-active:nth-child(5) {
    background-image: url("@/assets/images/common/bottomMenu_mypage_on.svg");
}
</style>