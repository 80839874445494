export default {
    namespaced : true,
    state : {
        owner : {
            category: {}, // categories 중 누른 category
            sub_category: {},  // sub_categories 중 누른 sub_category
        },
        ownerPop : {
            category : {},
            detail : {}
        }
    },
    mutations : {
        // ownerPop
        openOwnerPop(state, payload){
            state.owner.category = payload.category;
            state.owner.sub_category = payload.sub_category;
        },
        closeOwnerPop(state){
            state.owner.category = {};
            state.owner.sub_category = {}
        },
        // ownerSubPop
        openOwnerSubPop(state, payload){
            state.ownerPop.category = payload.category;
            state.ownerPop.detail = payload.detail;
        },
        closeOwnerSubPop(state){
            state.ownerPop.category = {};
            state.ownerPop.detail = {};
        },
    }
}