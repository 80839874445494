export default {
    namespaced : true,
    state:{
        weare:{
            ui_category: {}, // ui_categories 중 누른 category
            popContent: {}, // 밑으로 보낼 content. 객체 형태 다양
            popList: [], // 밑으로 보낼 누른 category의 list
        },
    },
    mutations:{
        // 디테일 Pop
        openDetailPop(state, payload){
            state.weare.ui_category= payload.ui_category;
            state.weare.popContent = payload.popContent;
        },
        closeDetailPop(state){
            if(state.popList != []){
                state.weare.sub_category = {};
            } else{
                state.weare.ui_category= {};
                state.weare.sub_category = {};
            }
        },
        // 리스트 Pop
        openListPop(state, payload){
            state.weare.ui_category= payload.ui_category;
            state.weare.popList = payload.popList;
        },
        closeListPop(state){
            state.weare.ui_category= {};
            state.weare.popList = [];
        },
    }
}