<template>
  <div id="owner">
    <template
      v-for="category in this.categories"
      :key="category.category_pk"
    >
      <!-- 견적 서비스 START -->
      <section class="esti"
        v-if="category.code == '011'"
      >
        <div>
          <h2 class="sectionTitle">{{category.name}}</h2>
          <p class="sectionText">{{category.content}}</p>
          <!-- <ul class="list_container col_2"> -->
          <ul class="list_container">
            <li class="list esti_content"
              @click="openEstimate"
            >
              AI 내 예산 맞춤 견적
            </li>
            <!-- <li class="list esti_content"
              @click="openUrl('https://ownersite.co.kr/estimate/select')"
            >
              내가 선택하는 견적
            </li> -->
          </ul>
        </div>
      </section>
      <!-- 견적 서비스 END -->

      <!-- 인테리어/간판 서비스 START -->
      <section class="interior"
        v-else-if="category.code == '001'"
      >
          <h2 class="sectionTitle">{{category.name}}</h2>
          <p class="sectionText">{{category.content}}</p>
          <ul class="list_container col_2">
            <li class="list" 
              v-for="sub_category in this.sub_categories[category.code]" 
              :key="sub_category.category_pk"
              @click="openOwnerPop(category, sub_category)"
            >
              {{sub_category.name}}
            </li>
          </ul>
      </section>
      <!-- 인테리어/간판 서비스 END -->
      
      <!-- 구매 서비스 START -->
      <section class="purchase"
        v-else-if="category.code == '002'"
      >
        <h2 class="sectionTitle">{{category.name}}</h2>
        <p class="sectionText">{{category.content}}</p>
        <ul class="list_container col_2">
          <li class="list"
            v-for="sub_category in this.sub_categories[category.code]"
            :key="sub_category.category_pk"
            @click="openOwnerPop(category, sub_category)"
          >
            {{sub_category.name}}
          </li>
        </ul>
      </section>
      <!-- 구매 서비스 END -->

      <!-- 렌탈, 세무, 보험, 마케팅, 투자/자문/인증, 구인/구직, 개발, 디자인 서비스 START -->
      <section
        v-else
      >
        <h2 class="sectionTitle">{{category.name}}</h2>
        <p class="sectionText">{{category.content}}</p>
        <swiper v-bind="swiperOptions">
          <swiper-slide
            v-for="sub_category in this.sub_categories[category.code]"
            :key="sub_category.category_pk"
            @click="openOwnerPop(category, sub_category)"
          >
            <div class="imgWrapper">
              <img :src="sub_category.path+sub_category.save_name" :alt="sub_category.original_name">
            </div>
            <div class="tagBox">
              <p>{{sub_category.name}}</p>
            </div>
          </swiper-slide>
        </swiper>
      </section>
      <!-- 렌탈, 세무, 보험, 마케팅, 투자/자문/인증, 구인/구직, 개발, 디자인 서비스 END -->
    </template>
  </div>
</template>

<script>
// ---------------Swiper---------------
import SwiperCore, {Autoplay, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import "swiper/css/pagination"
SwiperCore.use([Autoplay, Pagination]);
// ---------------Swiper---------------

// import { openUrl } from '@/utils/openUrl'

export default {
  name: 'Owner',
  components: {
    Swiper,
    SwiperSlide,
  },
  created(){
    this.fetchOwnerData();
  },
  data(){
    return {
      categories:[],
      sub_categories:[],

      showEstiPop: false,

      swiperOptions:{
        loop:true,
        pagination:{
          clickable:true
        },
        autoplay:{
          delay: 3500,
          disableOnInteraction: false
        }
      }
    }
  },
  // mixins:[ openUrl ],
  methods:{
    fetchOwnerData(){
      const loadingTimeSet = setTimeout(() => {
        this.$store.commit('startLoading');
      }, 500);
      

      const url = '/weareowner/owner/main';
      this.axios.get(url)
      .then(res=>{
        this.categories = res.data.category;
        this.sub_categories = res.data.sub_category;

        clearTimeout(loadingTimeSet);
        this.$store.commit('endLoading');
      });
    },
    openOwnerPop(category, sub_category){
      this.$store.commit('owner/openOwnerPop', {
        category : category,
        sub_category : sub_category,
      })
      this.$router.push('/owner/ownerpop')
    },
    openEstimate(){
      if(this.$store.state.user.user.token){
        this.$router.push('/estimate');
      } else {
        this.$router.push('/login');
      }
    },
  }
}
</script>

<style scoped src="@/assets/css/css-custom/views/owner.css">
</style>