export default {
    namespaced : true,
    state : {
        home : {
            popContent : {},
        }
    },
    mutations : {
        openHomePop(state, payload){
            state.home.popContent = payload;
        },
        closeHomePop(state){
            state.home.popContent = {};
        }
    }
}