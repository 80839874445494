<template>
<div id="mypage">
  <!-- 프로필상단 START -->
  <section class="profile">
    <div class="profile_btn">
      <img src="@/assets/images/common/icon_notification.svg" class="notification_icon"
        @click="openMypagePop('notification')"
      >
      <img src="@/assets/images/common/icon_menu.svg" class="menu_icon"
        @click="openMypagePop('menu')"
      >
    </div>

    <div class="profile_text_container">
      <div class="profile_text">
        <div class="greeting">
          <span class="name">{{this.user.name}}</span>
          <span class="social" :class="[this.user.sign_path == '위아오너' ? 'owner' : this.user.sign_path]"></span>
        </div>
        
        <div class="set"
          v-if="this.user.self_certification_yn == 'true'"
          @click="openMypagePop('profile')">
          프로필 확인하기
        </div>
        <div class="set" v-else
          @click="$router.push('/certificate')"
        >
          본인 인증하기
        </div>
      </div>
      <div class="profile_img" id="profile_img">
        <img v-if="this.user.original_name"
          :src="this.user.path+this.user.save_name" :alt="this.user.original_name"
        />
      </div>
    </div>

    <div class="profile_point">
      현재 보유하신 포인트는 <strong>{{numberFormat(this.user.point)}}</strong>P 입니다.
      <div class="set" @click="openMypagePop('point')">포인트 내역보기</div>
    </div>
  </section>

  <!-- 탭 START -->
  <div class="tabs">
    <ul>
      <li class="tab on" id="tab1"
        @click="this.showTab(1)">
        <p>{{this.counts.startup}}</p>창업
      </li>
      <li class="tab" id="tab2"
        @click="this.showTab(2)">
        <p>{{this.counts.consulting}}</p>견적
      </li>
      <li class="tab" id="tab3"
        @click="this.showTab(3)">
        <p>{{this.counts.inquire}}</p>문의
      </li>
      <li class="tab" id="tab4"
        @click="this.showTab(4)">
        <p>{{this.counts.bookmark}}</p>저장
      </li>
    </ul>
  </div>
  <!-- 탭 END -->

  <!-- 창업탭 START -->
  <div class="tab_content" id="tab_content1"
    v-if="this.whichTab==1">
    <section>
      <ul class="list_container">
        <li class="list"
          v-for="item in startups"
          :key="item.estimate_pk"
          @click="()=>{
            this.mypage.startup = item;
            openMypagePop('startup');
          }"
        >
          {{dateFormat(item.enrollment_date).replaceAll('-', '.')}} - {{item.title}}
        </li>
        <li class="list"
          v-if="this.counts.startup == 0"
        >내역이 없습니다.</li>
      </ul>
    </section>
  </div>
  <!-- 창업탭 END -->

  <!-- 견적탭 START -->
  <div class="tab_content" id="tab_content2"
    v-if="this.whichTab==2">
    <section>
      <ul class="list_container">
        <li class="list"
          v-for="item in consultings"
          :key="item.estimate_pk"
          @click="()=>{
            this.mypage.consulting = item;
            openMypagePop('consulting');
          }"
        >
          {{dateFormat(item.enrollment_date).replaceAll('-', '.')}} - {{item.title}}
        </li>
        <li class="list"
          v-if="this.counts.consulting == 0"
        >내역이 없습니다.</li>
      </ul>
    </section>
  </div>
  <!-- 견적탭 END -->

  <!-- 문의탭 START -->
  <div class="tab_content" id="tab_content3"
    v-if="this.whichTab==3">
    <section>
      <ul class="list_container">
        <li class="list"
          v-for="item in this.inquires"
          :key="item.inquire_pk"
          @click="()=>{
            this.mypage.inquire = item;
            openMypagePop('inquire');
          }"
        >
          {{dateFormat(item.enrollment_date)}} - {{item.title}}
        </li>
        <li class="list"
          v-if="this.counts.inquire == 0"
        >내역이 없습니다.</li>
      </ul>
    </section>
  </div>
  <!-- 문의탭 END -->

  <!-- 저장탭 START -->
  <div class="tab_content" id="tab_content4"
    v-if="this.whichTab==4">
    <section>
      <ul class="list_container">
        <li class="list"
          v-for="item in this.bookmarks"
          :key="item.contents_pk"
          @click="()=>{
            this.mypage.bookmark = item;
            openMypagePop('bookmark');
          }"
        >
          {{item.title}}
        </li>
        <li class="list"
          v-if="this.counts.bookmark == 0"
        >내역이 없습니다.</li>
      </ul>
    </section>
  </div>
  <!-- 기부탭 END -->

  <!-- mypage-pop START -->
  
  <!-- mypage-pop END -->
</div>
</template>

<script>
import { mapState } from 'vuex'
import { numberFormat } from '@/utils/numberFormat'
import { dateFormat } from '@/utils/dateFormat'

export default {
  name: 'Mypage',
  mounted(){
    this.fetchMypageData();
  },
  computed:{
    ...mapState('user', [ 'user' ]),
    ...mapState('mypage', [ 'mypage' ]),
  },
  data(){
    return{
      whichTab : 1,

      counts : {},
      consultings : [],
      startups : [],
      inquires : [],
      bookmarks : [],
    }
  },
  mixins: [ numberFormat, dateFormat ],
  methods:{
    fetchMypageData(){
      let isFetchDone = false;
      const loadingTimeSet = setTimeout(() => {
        this.$store.commit('startLoading');
      }, 500);

      const url = '/weareowner/mypage'
      this.axios.get(`${url}/main`, {
        params : {
          access_token : this.user.token,
        }
      })
      .then(res=>{
        if(isFetchDone){
          clearTimeout(loadingTimeSet);
          this.$store.commit('endLoading');
        } else {
          isFetchDone = true;
        }
        
        if(!res.data.success){
          this.$store.commit('user/logout');
          this.$router.push('/login');
        }
        
        this.counts = res.data.counts;
      });

      this.axios.get(`${url}/startup`, {
        params:{
          access_token : this.user.token,
        }
      }).then(res=>{
        this.startups = res.data.estimate;
        if(isFetchDone){
          clearTimeout(loadingTimeSet);
          this.$store.commit('endLoading');
        } else {
          isFetchDone = true;
        }
      })
    },
    showTab(num){
      const url = '/weareowner/mypage';
      if(this.whichTab!=num){
        document.getElementById(`tab${this.whichTab}`).classList.remove("on");
        document.getElementById(`tab${num}`).classList.add("on");
        if(num == 1){
          const loadingTimeSet = setTimeout(() => {
            this.$store.commit('startLoading');
          }, 500);
          this.axios.get(`${url}/startup`, {
            params:{
              access_token : this.user.token,
            }
          }).then(res=>{
            this.startups = res.data.estimate;
            clearTimeout(loadingTimeSet);
            this.$store.commit('endLoading');
          })
        }
        if(num == 2){
          const loadingTimeSet = setTimeout(() => {
            this.$store.commit('startLoading');
          }, 500);
          this.axios.get(`${url}/consulting`, {
            params:{
              access_token : this.user.token,
            }
          }).then(res=>{
            this.consultings = res.data.estimate;
            clearTimeout(loadingTimeSet);
            this.$store.commit('endLoading');
          })
        }
        if(num == 3){
          const loadingTimeSet = setTimeout(() => {
            this.$store.commit('startLoading');
          }, 500);
          this.axios.get(`${url}/inquire`, {
            params:{
              access_token : this.user.token,
            }
          }).then(res=>{
            this.inquires = res.data.inquire;
            clearTimeout(loadingTimeSet);
            this.$store.commit('endLoading');
          })
        }
        if(num == 4){
          const loadingTimeSet = setTimeout(() => {
            this.$store.commit('startLoading');
          }, 500);
          this.axios.get(`${url}/bookmark`, {
            params:{
              access_token : this.user.token,
            }
          }).then(res=>{
            this.bookmarks = res.data.contents;
            clearTimeout(loadingTimeSet);
            this.$store.commit('endLoading');
          })
        }
        this.whichTab=num;
      }
    },
    openMypagePop(whichPop){
      this.$store.commit('mypage/openMypagePop', whichPop);
      this.$router.push('/mypage/mypagepop');
    },
  }
}
</script>

<style scoped src="@/assets/css/css-custom/views/mypage.css">
</style>
