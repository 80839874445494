export default {
    namespaced : true,
    state:{
        supportPop:{
            detail : {}
        },
    },
    mutations:{
        openSupportSubPop(state, payload){
            state.supportPop.detail = payload;
        },
        closeSupportSubPop(state){
            state.supportPop.detail = {};
        }
    }
}