<!-- Spinner.vue -->
<template>
    <div id="loading" v-if="this.$store.state.loadingStatus">
        <div>
            <img src="@/assets/images/weare_loading.gif" alt="loading">
            <p>loading...</p>
        </div>
    </div>
</template>
<script>
export default {
}
</script>

<style scoped>
#loading {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgba(255, 255, 255, .65);
}
#loading img{
    width: 9rem;
    height: 9rem;
}
#loading p{
    font-size: 2rem;
    margin-top: 1rem;
}
</style>